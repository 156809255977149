import { getSession } from "next-auth/react";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
	baseUrl: `${process.env.NEXT_PUBLIC_BACKEND_URL}/`,

	credentials: "same-origin",
	prepareHeaders: async (headers) => {
		const session = await getSession();
		if (session) {
			headers.set("Authorization", `Bearer ${session.idToken}`);
			return headers;
		}
	},
});

export const Api = createApi({
	baseQuery,
	tagTypes: [
		"order",
		"category",
		"chats",
		"saved",
		"blog",
		"blog-comments",
		"blog-likes",
		"affiliates",
		"user",
		"plans",
		"services",
	],
	endpoints: (builder) => ({}),
});
