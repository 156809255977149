export interface Participant {
	onlineStatus: UserOnlineStatus;
	_id: string;
	firstName: string;
	lastName: string;
	email: string;
	avatar?: string;
	stripeSubscriptionId: string | null;
}

export enum UserOnlineStatus {
	ONLINE = "ONLINE",
	OFFLINE = "OFFLINE",
	AWAY = "AWAY",
	BUSY = "BUSY",
	OUT_OF_OFFICE = "OUT_OF_OFFICE",
	ON_ANNUAL_LEAVE = "ON_ANNUAL_LEAVE",
}

export interface Chat {
	_id: string;
	participants: Participant[];
	createdAt: string;
	updatedAt: string;
	__v?: number;
	id?: string;
}

export type CreateChatBody = { participants: string[] };

export interface ChatApiResponse {
	docs: Chat[];
	totalDocs: number;
	limit: number;
	totalPages: number;
	page: number;
	pagingCounter: number;
	hasPrevPage: boolean;
	hasNextPage: boolean;
	prevPage: null;
	nextPage: null;
}

export interface ChatMessage {
	_id: string;
	content: string;
	senderId: Participant | null;
	chatId: string;
	replyToMessageId: string | null;
	isRead: boolean;
	fileUrls: string[];
	createdAt: string;
	updatedAt: string;
	__v: number;
	id: string;
}

export interface ChatMessageApiResponse {
	docs: ChatMessage[];
	totalDocs: number;
	limit: number;
	totalPages: number;
	page: number;
	pagingCounter: number;
	hasPrevPage: boolean;
	hasNextPage: boolean;
	prevPage: null;
	nextPage: null;
}

export type Message = {
	isReceived: boolean;
	content: string;
	createdAt: string;
	fileUrls: string[];
};
