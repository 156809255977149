import type { User } from "@/types";
import type { Order, ServerCollectionResponse } from "@/types/businessTypes";

export enum CommissionRate {
	Ten = 10,
	Twenty = 20,
	Thirty = 30,
	Forty = 40,
}

export const CommissionRateMappings = {
	[CommissionRate.Ten]: 10,
	[CommissionRate.Twenty]: 20,
	[CommissionRate.Thirty]: 30,
	[CommissionRate.Forty]: 40,
};

export enum AffiliateStatus {
	PENDING = "PENDING",
	ELIGIBLE = "ELIGIBLE",
	INELIGIBLE = "INELIGIBLE",
	REQUESTED = "REQUESTED",
	REDEEMED = "REDEEMED",
}

export enum AffiliateDuration {
	SIX_MONTH = 6,
	ONE_YEAR = 12,
	TWO_YEARS = 24,
}

export const AffiliateDurationMappings = {
	[AffiliateDuration.SIX_MONTH]: "6 Months",
	[AffiliateDuration.ONE_YEAR]: "1 Year",
	[AffiliateDuration.TWO_YEARS]: "2 Years",
};

export interface UserAffiliateInfo {
	commission: CommissionRate;
	duration: AffiliateDuration;
}

export type AffiliateUser = Pick<User, "_id" | "firstName" | "lastName" | "email" | "avatar"> & {
	createdAt: string;
	affiliateCount: number;
};

export interface Affiliate {
	_id: string;

	order: Order;
	subOrder: string;

	/** Value after calculating the commission rate and sub order value */
	value: number;
	status: AffiliateStatus;
	commissionRate: CommissionRate;

	affiliateTo: AffiliateUser;
	affiliateBy: AffiliateUser;

	createdAt: string;
	updatedAt: string;
}

export type AffiliateCollection = ServerCollectionResponse<Affiliate>;

export type ReferralUser = Pick<User, "_id" | "firstName" | "lastName" | "email" | "avatar"> & {
	referralCount: number;
};

/** Types used as part of FE convenience transformations */
export type ModdedAffiliate = Omit<Affiliate, "createdAt"> & { createdAt: Date };
export type MonthGrouped = { items: ModdedAffiliate[]; total: number };
